@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.body {
  height: 100vh;
  width: 100%;
}

.tagline {
  position: relative;
  text-align: right;
  margin-left: auto;
  margin-right: 1em;
  top: -50px;
}

@media not all and (min-width: 640px) {
  .tagline {
    top: -20px;
  }
}
